/**
 * Here are all the Backend api calls for our modules
 */
import appApiClient from '../app-api-client'

const pageUriPrefix = "/admin/clients";

export default {

    getIndexData() {
        return appApiClient().get(pageUriPrefix+'/index');
    },
}